
body{
    width:100%;
    margin-left:auto;
    margin-right:auto;
    /* background-image:url(./images/fond_vert.jpg); */
    /* background-color: #BBE8C4; */
    background: linear-gradient(to bottom right, #feffe9 30%,#d1e7ba 60%, #c3e3c3);
    background-size: cover;
    background-attachment:fixed;
    background-position:right top;
    /* font-family: Proxima Nova Soft,Helvetica,sans,Verdana,Arial,Bookman,Georgia ,"time new roman"; */
    font-family: 'Titillium Web', sans-serif;
    font-size: 20px;
    
}

.fondgris{
    background-color:#d2d2d2; /* gris */
}

span{
    font-family: 'Titillium Web', sans-serif;
}

/* les liens*/
a{
    color:#4d4d4d; 
    /*font-weight: bold;*/
    text-decoration:none;
    outline: 0; /* suppr dotted outline shown when visited */
}
a:link
{
    text-decoration:none; /* suppr soulignement lien */
} 
a:hover
{
    color:white; /* suppr soulignement lien */
} 

a.liensVerts {
    color:#225e08 
}

.nav-link{
    color: white; 
    height: 100%;
    text-align: center;
    vertical-align: middle;
}

.nav-link:hover{
    color: #d9f2d9;
    /* font-weight: bold;  */
}

header{
    background-color: #168216;  /* vert agrivore gche */ /*test : 65b565*/
}

#menu{
    width:20%;
    float:left;
    
}
.sous_menu h3{  
    color:red;
    text-align:center;
    background-color: #d2d2d2;
   
}
    
.sous_menu {
    margin-top:0px;
    border:0px dashed black;
    padding:20px;
    background-color:#d2d2d2;
}
#sous_menu{
    margin-top:0px;
    border:0px dashed black;
    padding:0px;
    background-color:#d2d2d2;
}

#corps
{
   background-color:white;    
   text-align:justify;
   padding:10px;
   color:#31809a;
   border:6px inset;
   border-radius: 10px;
}
#corps h1{
   
   color:#34a3b0;
   text-align:center;
   border-radius: 10px;
}

#corps h2{
    text-align:center;
    color:#584c4c;
    border:3px ridge;
    background-color: #d2d2d2;
    font-size : large;
    border-radius: 10px;
}

#corps h3{
    text-align:left;
    color:#837474;     
    font-size:0.5;
    border-radius: 10px;
    
 }
 
#corps h4{
    text-align: left;
    color:#34a3b0;     
    font-size : smaller;
    border-radius: 10px;
 }
 
#corps h5{
    text-align:center;    
    color:#B9121B;    
    border:2px inset;
    background-color: #d2d2d2;
    font-size : large;
    border-radius: 10px;
}


#pied_de_page{
    clear:left;
    margin-top:0px;
    padding:5px;
    background-color: #d2d2d2;
    text-align:center;
    border:0px dashed black;
}
#pied_de_page h6{
    clear:left;
    margin-top:0px;
    padding:5px;
    background-color: #d2d2d2;
    text-align:center;
    border:0px dashed black;
}
/* paragraphe */
p
{
    width:100%;
    margin-left:auto;
    margin-right:auto;
    
    text-align: center;
    /*font-family:Verdana,Arial,Bookman,Georgia ,"time new roman",bookman,georgia,arial;*/
    color: #112f04; /* vert fonce pied de page remplace bleu A01 rgb(40,116,162); */
    font-family: 'Titillium Web', sans-serif;
}

/* les tableaux*/
table  td
{
border-style:solid;
border-width:0px;
border-color: white;
border-collapse: collapse;
}


/* les listes*/
ul{
list-style-position:inside;
list-style-type: square;
}


/* Bootstrap overrides */
.navbar .navbar-collapse {
  text-align:center;
}
.navbar .navbar-nav {
    display:inline-block;
    float:none;
}
.navbar-toggle .icon-bar{ background-color: #fff;}

/* AN ajouts */

.cardshaddow {
    /*box-shadow: 5px 5px 25px 0px rgba(46,61,73,0.2);/* /*default*/
    box-shadow: 5px 5px 15px 0px rgba(43, 117, 11, 1.2);
    margin-bottom: 1.5rem; /* pour espacer cards d une meme row en affichage mobile */
}
.card {
    /*box-shadow: 5px 5px 25px 0px rgba(46,61,73,0.2);/* /*default*/
    box-shadow: 5px 5px 15px 0px rgba(43, 117, 11, 1.2);
    margin-bottom: 1.5rem; /* pour espacer cards d une meme row en affichage mobile */
}
.card-block {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
    color: #1a4607; /* vert fonce A01 */
}

.card-block-an {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0.5rem;
    color: #1a4607; /* vert fonce A01 */
}

.card-deck-homepage {
    position: relative;
    padding: 1.5rem;
    margin: 1rem -1rem;
    /*border: solid #f7f7f9;*/
    border-width: .2rem 0 0;
}

.card-text {
    color: #225e08; /*#194606; #112f04; vert fonce A01 plus fonce */
    font-size: 1em;
    font-family: 'Titillium Web', sans-serif;
}

.card-title {
    color : #225e08;
    font-family: 'Chewy', cursive;
    text-shadow: 1px 1px #112f04;
}

.mapCheckbox{
    font-family: 'Titillium Web', sans-serif;
}

.titlecard { /* AN fond vert */
    display: block;
    width: auto;
    height: auto;
    background: #008900;
    /*padding: 10px; btn index*/
    text-align: center;
    border-radius: 5px;
    color: white;
    font-size:1em;
    font-weight: bold;
    border-color: white;
}

.btncard {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    display: block;
    width: auto;
    height: auto;
    background: white;
    /*padding: 10px;*/
    text-align: center;
    border-radius: 1000px;
    color: #008900;
    font-size:1.5vw;
    font-weight: bold;
    border-color: #008900;
    border-width: 2px;
}
.btncard:hover {
  color: white;
  text-decoration: none;
  /*background: #44bb11;*/
  background: #008900;
  border-color: #008900;
}
.btncard:visited{
    border-color: green;
    outline-color: green;
}


.btncardlink {
    /* padding-top: 0.4em; */
    /* padding-bottom: 0.4em; */
    display: block;
    width: auto;
    height: auto;
    background: white;
    /*padding: 10px;*/
    text-align: center;
    border-radius: 10px;
    color: #008900;
    font-size:1em;
    font-weight: bold;
    border-color: #008900;
    border-width: 2px;
    
    /* from .card */
    box-shadow: 5px 5px 15px 0px rgba(43, 117, 11, 1.2);
    margin-bottom: 0.2rem; /* pour espacer cards d une meme row en affichage mobile */
    word-wrap: break-word;
}
.btncardlink:hover {
  color: white;
  text-decoration: none;
  /*background: #44bb11;*/
  background: #008900;
  border-color: #008900;
}
.btncardlink:visited{
    border-color: green;
    outline-color: green;
}



.btncardred {
    display: block;
    width: auto;
    height: auto;
    background: white;
    /*padding: 10px;*/
    text-align: center;
    border-radius: 1000px;
    color: #c92135;
    font-size:1vw;
    font-weight: bold;
    border-color: #c92135;
    border-width: 2px;
}
.btncardred:hover {
  color: white;
  text-decoration: none;
  /*background: #44bb11;*/
  background: #c92135;
  border-color: #c92135;
}
.btncardred:visited{
    border-color: #B81414;
    outline-color: #B81414;
}



.en_cours_construction{
    display: block;
    width: auto;
    height: auto;
    background: #fbe9ec;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: #4d4d4d;
    font-size:40px;
    font-weight: bold;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: auto;
  background: #eefde8;
  border-top: 1px solid #3ca30f;   
}


.svg-icon {
  display: inline-flex; /* inline-block? */
  align-self: center;
  }
.svg-icon svg {
  height:1em;
  /*width:1em;*/
}
.svg-icon.svg-baseline svg {
  top: .125em;
  position: relative;
}

/* .svg-container { */
	/* display: inline-block; */
	/* position: relative; */
	/* width: 100%; */
	/* padding-bottom: 100%;  */
	/* vertical-align: middle;  */
	/* overflow: hidden;  */
/* } */
/* .svg-content { */
	/* display: inline-block; */
	/* position: absolute; */
	/* top: 0; */
	/* left: 0; */
/* } */



.leaflet-container a{ /* override AN */
  color: #008900;
}
.leaflet-container a:hover:not(.fbtn):not(.carousel-control-next):not(.carousel-control-prev){ /* override AN + LB exclusion btn carrousel des liens rendus verts au survol */
  color: white;
  text-decoration: none;
  background: #008900;
  border-color: #008900;
}

/* LB menu responsive 3 barres */

.topnav {
    overflow: hidden;
  }
  
.topnav a {
float: right;
color: #f2f2f2;
text-align: center;
align-content: center;
padding: 14px 16px;
text-decoration: none;
}

.topnav a:hover {
background-color: #ddd;
color: black;
}

.topnav .icon { /* icone 3 barres */
display: none;
}

@media screen and (max-width: 800px) {  /* Affichage ou non du texte/de l'icone selon la taille de l'écran */
.topnav a:not(:first-child) {display: none;}
.topnav a:first-child {float: left;}
.topnav a.icon {
    float: right;
    display: block;
}
}

@media screen and (max-width: 800px) {  /* Affichage du texte sous le nouveau format */
.topnav.responsive {position: relative;}
.topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
.topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
}
.topnav.responsive a:hover{
    background-color: #ddd;
    color: black;
}
}

@media screen and (min-width: 767px) { /* LB : affichage des barres horizontales sur departement (delimitation quand ecran trop petit */
.barreHorizontale{
    display:none;
}
}

/*LB : carte SVG */
#CarteFrance{
    max-width: 100%;
    max-height: 100%;
}
#CarteFrance path:not(.DepartementRempli ) {
fill:#f0f3f7;
stroke:#544f4f;
stroke-width:0.6;
transition: fill 0.2s, stroke 0.3s;

}
#CarteFrance path:hover {
fill: rgb(21, 99, 17);
stroke-width:1.5;

}
#CarteFrance .separator {
stroke: #ccc;
fill: none;
stroke-width:1.5;
}
#CarteFrance .separator:hover {
stroke: #ccc;
fill: none;
}

.DepartementRempli{
    fill:#61e461;
    stroke:#544f4f;
    stroke-width:0.6;
    transition: fill 0.2s, stroke 0.3s;
}
/*LB : fin carte SVG */

.linkConsultation a:hover{
    color:black;
    font-weight: bold;
}

.carousel-indicators > li{ 
    height: 25px;
    content: url('./images/next.svg'); /* img chevrons */
    background-color: #c4e3bc;
}


/* LB : boutons partage Carousel */
.social .fbtn {
    width: 50px;
    height: 50px;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 18px;
    margin: 5px;
    border-radius: 10%;
}
.social .fa {
    padding: 15px 0px
}
.facebook{
    background-color: #3b5998;
}
.twitter {
    background-color: #55acee;
}
.stumbleupon {
    background-color: #eb4924;
}
.pinterest {
    background-color: #cc2127;
}
.linkedin {
    background-color: #0077b5;
}
.vk{
    background-color: #4f83ba;
}
.fbtn:hover{
    transform: scale(1.2);
}

.linkPasBlanc:hover{
    color:black;
}

.centerBlock{
    display: table;
    margin:auto;
}

/* btn liste deroulante articles producteurs */
.btn-outline-success-lb {
    color: #225e08;
    border-color: #225e08;
}

.btn-outline-success-lb:hover {
    color: #fff;
    background-color: #225e08;
    border-color: #225e08;
}
/* FIN btn liste deroulante articles producteurs */

/* bloc pour couleur JAUGE desactive style par defaut du navigateur : style input type range (pour producteur a moins de X km */
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
      overflow: hidden;
      -webkit-appearance: none;
    }
    
    input[type='range']::-webkit-slider-runnable-track {
      height: 10px;
      -webkit-appearance: none;
      color: #225e08;
      margin-top: -1px;
    }
    
    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      cursor: ew-resize;
      background: #434343;
      box-shadow: -80px 0 0 80px #225e08;
      width: 10px;
      height: 10px;
    }

}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #225e08 ; 
}
input[type="range"]::-moz-range-track {  
  background-color: #9a905d;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7; 
}
input[type="range"]::-ms-fill-upper {  
  background-color: #9a905d;
}
/* FIN style input type range (pour producteur a moins de X km */


/* FONTS */
.font-Chewy{
    font-family: 'Chewy', cursive;;
}